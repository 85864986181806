// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  GEOSERVER_URL: 'http://192.168.20.114:8091/geoserver',
  GEOSERVER_OWS_URL: 'http://192.168.20.114:8091/geoserver/ankamap/ows',
  GEOSERVER_WMS_LAYER: 'http://192.168.20.114:8091/geoserver/ankamap/wms',

  // GEOSERVER_OWS_URL: "http://172.17.1.21:8080/geoserver/YTB/ows",
  // GEOSERVER_URL : "http://172.17.1.21:8080/geoserver",
  // GEOSERVER_WMS_LAYER :  "http://172.17.1.21:8080/geoserver/YTB/wms",

 // Authentication_URL: 'http://130.211.180.183/public/api/'
}

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
